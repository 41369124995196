
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          title: "Our restaurant",
          subTitle: `Kindness, hospitality and a warm smile make our guests feel welcome at home! We like to prepare simple dishes firmly attached to the <strong>Molise tradition</strong>, following the seasonality of the products: from the Earth to the Table. We mainly use <strong>fresh products from the garden</strong>, including EVO olive oil produced by us, or from local farms. Our Menu changes every week to satisfy regular guests and amaze those who come to visit us for the first time! In fact, there are some evenings based on seafood dishes, and vegetarian dishes always abound, many fresh pasta first courses, risottos, vegetable flans, cheeses ... If communicated in time, our restaurant will be happy to answer to the needs of particular diets, such as that of celiacs, or vegans.`,
          contact: `For information, availability and reservations write to 
            <strong class='q-pl-xs'>{account}{'@'}{domain}</strong>
            or contact us at
            <strong class='q-pl-xs'>+39 0875 911044</strong>.`,
        },
        it: {
          title: "Il nostro ristorante",
          subTitle: `Gentilezza, ospitalità e un caldo sorriso fanno sentire i nostri ospiti benvenuti a Casa! Ci piace preparare piatti semplici e saldamente attaccati alla <strong>tradizione molisana</strong>, seguendo la stagionalità dei prodotti: dalla Terra alla Tavola. Utilizziamo principalmente i <strong>prodotti freschi dell'orto</strong>, tra cui l'olio di oliva EVO da noi prodotto, o provenienti da fattorie locali. Il nostro Menu cambia ogni settimana per accontentare gli ospiti abituali e stupire chi viene per la prima volta a trovarci! Non mancano, infatti, alcune serate a base di piatti di mare, ed abbondano sempre i piatti vegetariani, molti primi di pasta fresca , i risotti, gli sformatini di verdure, i formaggi… Se comunicato per tempo, il nostro ristorante sarà felice di rispondere alle esigenze di regimi alimentari particolari, come quello dei celiaci, o dei vegani.`,
          contact: `Per informazioni, disponibilità e prenotazioni scrivere a 
             <strong class='q-px-xs'>{account}{'@'}{domain}</strong>
            oppure contattateci al
            <strong class='q-pl-xs'>+39 0875 911044</strong>.`,
        },
      },
    });

    useMeta({
      title: "Ristorante",
      description: `Gentilezza, ospitalità e un caldo sorriso fanno sentire i nostri ospiti benvenuti a Casa! Ci piace preparare piatti semplici e saldamente attaccati alla <strong>tradizione molisana</strong>, seguendo la stagionalità dei prodotti: dalla Terra alla Tavola. Utilizziamo principalmente i <strong>prodotti freschi dell'orto</strong>, tra cui l'olio di oliva EVO da noi prodotto, o provenienti da fattorie locali. Il nostro Menu cambia ogni settimana per accontentare gli ospiti abituali e stupire chi viene per la prima volta a trovarci! Non mancano, infatti, alcune serate a base di piatti di mare, ed abbondano sempre i piatti vegetariani, molti primi di pasta fresca , i risotti, gli sformatini di verdure, i formaggi… Se comunicato per tempo, il nostro ristorante sarà felice di rispondere alle esigenze di regimi alimentari particolari, come quello dei celiaci, o dei vegani.`,
    });
    return {
      t,
      slide: ref(1),
      fullscreen: ref(false),
      piatti: [
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393934/casa-in-campagna/ristorante/IMG_20210613_132308_y9fy6w.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393937/casa-in-campagna/ristorante/IMG_20210613_132324_k0iyzf.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393917/casa-in-campagna/ristorante/IMG_20210614_210105_bcmbsa.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393917/casa-in-campagna/ristorante/12705231_473827089485613_4043087451753771935_n_plc4nk.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393912/casa-in-campagna/ristorante/17951573_664586103743043_77732338597594068_n_xnrrcv.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393912/casa-in-campagna/ristorante/23244108_762408650627454_7190763553592543463_n_lkxh64.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393906/casa-in-campagna/ristorante/23167825_762408643960788_2578085847539910101_n_uiupht.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393905/casa-in-campagna/ristorante/24293881_775293309338988_7275637266496328424_n_vrbzgx.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393901/casa-in-campagna/ristorante/IMG_20190809_202932_adrmlw.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393898/casa-in-campagna/ristorante/IMG_20210613_134123_eouhxx.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393893/casa-in-campagna/ristorante/47032339_1006257982909185_4343558760690614272_o_ixyhvj.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393919/casa-in-campagna/ristorante/IMG_20210614_212929_mlgump.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393915/casa-in-campagna/ristorante/IMG_20210614_212935_w8vcxg.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393908/casa-in-campagna/ristorante/IMG_20210613_135225_bauvlf.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393893/casa-in-campagna/ristorante/61833581_1123388651196117_8630414431131533312_n_tqkaq9.jpg",
        "https://res.cloudinary.com/dlnuo1fcn/image/upload/v1629393903/casa-in-campagna/ristorante/38938461_936756783192639_6980665025417445376_n_rdekja.jpg",
      ],
    };
  },
  data() {
    return {
      showPiattiGallery: false,
      index: 0,
    };
  },
  methods: {
    showPiatti(index: number) {
      this.index = index;
      this.showPiattiGallery = true;
    },
  },
});
