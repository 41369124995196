import { render } from "./Ristorante.vue?vue&type=template&id=00d9507e"
import script from "./Ristorante.vue?vue&type=script&lang=ts"
export * from "./Ristorante.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QCarousel,QCarouselSlide,QCarouselControl,QBtn});
